<template>
  <v-app class="copilot">
    <v-app-bar color="var(--theme-identite)" elevation="0">
      <div class="copilot-header mx-auto">
        <div class="header-item">
          <ajouter-vehicule v-if="isOnVehiculesPage" ref="modaleAjoutVehicule" />
          <div v-else-if="!isOnTutoriel" class="ml-2 header-item" style="flex-basis: 33%">
            <v-btn icon color="var(--theme-icone)" @click="$router.back()">
              <icon-return filled style="font-size: 25px" />
            </v-btn>
          </div>
        </div>
        <div
          class="d-flex justify-center align-center header-logo color-white"
          :class="{'font-size-14': name === 'xs'}"
        >
          <b> {{ $t(`routes['${route.fullPath}']`) }}</b>
        </div>
        <div class="d-flex justify-end align-end mr-2 header-item">
          <v-btn v-if="isOnVehiculesPage" icon color="var(--theme-icone)" @click="navigateTo('/mon-compte')">
            <icon-account filled style="font-size: 25px" />
          </v-btn>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <slot />
    </v-main>
    <div v-if="loaderStore.isLoading()" class="progress-layout d-flex align-center justify-center">
      <v-progress-circular color="var(--theme-icone)" indeterminate />
    </div>
  </v-app>
</template>
<script setup lang="ts">
const userStore = useUserStore();
const modaleAjoutVehicule = ref(null);
const loaderStore = useLoaderStore();

const {name} = useDisplay();

const route = useRoute();
onMounted(() => {
  userStore.initActifs();
});

const isOnVehiculesPage = computed(() => {
  return route.path === '/vehicules';
});

const isOnTutoriel = computed(() => {
  return route.path === '/tutoriel';
});
</script>
<style scoped>
.copilot-header {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  width: 100%;
}

.header-logo {
  width: 100%;
}

.header-item {
  flex-basis: 33%;
}
</style>
